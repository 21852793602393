.description-page {
    margin-bottom: 36px;
}

.description-page p,
.description-page h2 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.description-page .card {
    background-color: var(--accent-sea-blue-light);
    color: var(--accent-sea-blue);
    border-radius: 1.5rem;
    margin: 6rem auto;
    max-width: var(--max-width);
    padding: 3rem;
    text-align: center;
    width: 90%;
}

.description-page .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.description-page .data {
    width: 200px;
}

.description-page .number {
    font-size: 72px;
}

.description-page .margin-h {
    margin: 90px auto;
}

.description-page .margin-h .button-like {
    margin-top: 36px;
    display: inline-block;
}

.description-page .margin-h p,
.description-page .margin-h li {
    line-height: 1.8;
    margin-bottom: 12px;
}

.description-page .left p {
    text-align: left;
}

.description-page .grid {
    gap: 64px;
}

.description-page .private-space {
    background-color: var(--accent-grey-0);
    margin-top: 2rem;
    padding-top: 4rem;
}

.description-page .private-space p {
    margin-bottom: 36px;
}
