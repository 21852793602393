.connect {
    font-size: 1.5rem;
    color: var(--text-color);
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.connect p {
    display: block;
    font-size: 1rem;
}

.connect button {
    font-weight: bold;
    color: var(--text-color);
    border: solid 1px;
    border-radius: 2rem;
    text-decoration: none;
    background-color: transparent;
    padding: 0.5em 1em;
    transition: all 0.25s;
}

.connect button:hover,
.connect button:focus {
    background-color: var(--accent-dark-blue);
    color: var(--accent-dark-blue-text-color);
}
