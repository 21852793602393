#layout {
    position: relative;
    margin: 0 auto;
}

header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--accent-sea-blue);
}

header h1 {
    text-align: center;
    font-size: 2rem;
    margin: 0;
    padding-top: 24px;
}

#subtitle {
    text-align: center;
    font-size: 1rem;
    margin: 0;
}

footer {
    background-color: var(--accent-orange-light);
    color: var(--accent-orange-light-text-color);
    padding: 1.5rem;
}

footer .footer-wrapper {
    width: 100%;
    max-width: var(--max-width);
    margin: auto;
}

footer div {
    /* width: max-content; */
}

footer img {
    max-height: 3rem;
}

.footer__links__separators {
    position: relative;
    bottom: 0.25rem;
    margin: 0 1rem;
}

footer a {
    font-weight: bold;
    text-decoration: none;
    color: var(--accent-orange-light-text-color);
    transition: all 0.25s;
}

footer a:hover,
footer a:focus,
footer button:hover,
footer button:focus {
    text-decoration: underline;
    color: var(--accent-dark-blue);
}

footer button {
    font-weight: bold;
    color: var(--accent-orange-light-text-color);
    border: none;
    text-decoration: none;
    background-color: transparent;
}

.header-button {
    width: fit-content;
}

@media (min-width: 660px) {
    .header-button {
        display: none;
    }
}
