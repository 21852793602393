.retry {
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 14rem);
    min-height: 11rem;
    max-width: var(--max-width);
    margin: auto;
}

.retry p,
.retry button {
    display: inline-block;
}

.retry p {
    font-weight: bold;
}

.retry button {
    color: var(--text-color);
    border: solid 1px;
    border-radius: 2em;
    text-decoration: none;
    background-color: transparent;
    padding: 0.5em 0.75em;
    transition: all 0.25s;
    margin-top: 0.5em;
    font-weight: bold;
    margin-right: 1em;
}

.retry button:hover,
.retry button:focus {
    background-color: var(--accent-dark-blue);
    color: var(--accent-dark-blue-text-color);
}
