.top-bar-wrapper {
    width: 100%;
}

.top-bar {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    max-width: var(--max-width);
    padding: 1rem;
    margin: auto;
}

.top-bar .logo {
    max-height: 40px;
    max-width: 50%;
    display: block;
}

.top-bar .logo img {
    max-width: 100%;
    display: block;
    object-fit: scale-down;
    height: 40px;
}

.top-bar .spacer {
    flex-grow: 1;
}

@media (max-width: 660px) {
    .top-bar .button-like {
        display: none;
    }
    .top-bar .logo {
        flex-grow: 2;
        max-width: 75%;
    }

    .top-bar .logo img {
        max-width: 100%;
        object-fit: scale-down;
    }
}
