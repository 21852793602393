.right-section,
.left-section {
    display: flex;
    align-items: center;
    text-align: center;
    max-width: var(--max-width);
    margin: auto;
    padding: 1rem;
}

.right-section {
    flex-direction: row-reverse;
}

.right-section img,
.left-section img {
    max-width: 40%;
    min-width: 300px;
    object-fit: scale-down;
    margin: 1rem;
}

.section-content {
    padding-bottom: 8px;
}

@media (width < 660px) {
    .right-section,
    .left-section {
        flex-direction: column-reverse;
    }
}
