.description-page ul.areas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 100px 5%;
    margin-bottom: 100px;
}

.description-page ul.areas li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 600px) {
    .description-page ul.areas li {
        width: 45%;
    }
}

@media (min-width: 900px) {
    .description-page ul.areas li {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .description-page ul.areas {
        padding: 1rem;
    }
}

.description-page ul.areas li img {
    height: 180px;
}

.description-page ul.areas li p {
    text-align: center;
}

.description-page .jland-banner {
    margin-bottom: -36px;
}
