#airspot {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    --col-size: max(320px, 25vw);
}

#airspot p {
    margin: 0;
}

#airspot #column-left-fixed {
    display: none;
}

#column-right {
    padding: 24px;
    width: 100vw;
}

#column-right {
    position: relative;
}

@media (min-width: 660px) {
    #column-right:after {
        content: '';
        position: absolute;
        background: url(../resources/nuage-5.svg);
        background-size: cover;
        right: -200px;
        width: 656px;
        height: 153px;
        top: 50px;
        transform: scale(0.55);
    }
}

#column-right h1 {
    font-size: 52px;
    line-height: 1.2;
    text-align: left;
    width: 450px;
}
#column-right h1 span {
    position: relative;
}
#column-right h1 span:before {
    content: '';
    position: absolute;
    bottom: 16px;
    left: -4px;
    right: -4px;
    height: 20px;
    border-radius: 2px;
    background-color: var(--accent-sea-blue);
    z-index: -1;
}

#column-right .go-to-home {
    font-size: 18px;
    font-weight: 700;
    margin: 16px 0;
}

@media (min-width: 780px) {
    #airspot #destinations-all {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }
}

#airspot #destinations-public,
#airspot #destinations-private {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

#airspot .destination {
    position: relative;
    border-radius: 12px;
    background-color: var(--accent-grey-1);
    padding: 12px 72px 12px 36px;
    transition: all 0.25s;
}

#airspot a.destination {
    text-decoration: none;
}

#airspot a.destination:hover {
    background-color: var(--accent-grey-2);
}

#airspot .destination:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 16px;
    border-radius: 12px 0 0 12px;
    background-color: var(--map-color);
}

#airspot a.destination:after {
    content: '';
    position: absolute;
    background: url(../resources/link-to.svg);
    background-size: cover;
    right: 24px;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s;
}

#airspot .destination a:not(.button-like) {
    position: relative;
    padding-left: 30px;
}

#airspot .destination a:not(.button-like):before {
    content: '';
    position: absolute;
    background: url(../resources/link-to.svg);
    background-size: cover;
    left: 0;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s;
}

#airspot .destination a:not(.button-like):hover:before {
    left: 4px;
}

#airspot .destination.new-office {
    position: relative;
    overflow: hidden;

    padding-left: 24px;
}

#airspot .destination.new-office .button-like {
    margin-left: 0;
}

#airspot .destination.new-office:after {
    content: '';
    position: absolute;
    background: url(../resources/laptop-diapazone.png);
    background-size: cover;
    right: -130px;
    width: 307px;
    height: 177px;
    top: 50%;
    transform: translateY(-45%) scale(0.6);
}

#airspot a.destination:hover:after {
    right: 18px;
}

#airspot .destination .title {
    font-size: 20px;
    font-weight: 700;
}

#airspot .destination {
    font-size: 14px;
    font-weight: 500;
}

@media (min-width: 1080px) {
    #airspot #column-left-fixed {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }
    #airspot #column-left-relative {
        position: relative;
        width: var(--col-size);
        background: linear-gradient(var(--accent-sea-blue), var(--accent-sea-green));
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        padding: 48px 16px;
        height: 100vh;
    }

    #airspot #column-left-relative:after {
        content: '';
        position: absolute;
        background: url(../resources/mongolfiere.png);
        background-size: cover;
        left: -35vh;
        width: calc(100vh * 512 / 654);
        height: 100vh;
        top: 50%;
        transform: translateY(-40%) rotate(-18deg) scale(0.65);
    }

    #airspot #column-left-relative .top-img {
        width: 90%;
        margin: auto;
    }

    #airspot #column-left-relative p {
        font-weight: 800;
        font-size: 28px;
        line-height: 1.2;
        color: white;
        text-align: center;
    }

    #airspot #column-left-relative a {
        color: white;
        text-align: center;
        z-index: 2;
        padding: 8px 16px;
    }

    #airspot #column-left-relative #made-with-love {
        display: flex;
        align-items: center;
        color: white;
        font-size: 14px;
    }

    #airspot #column-left-relative #made-with-love a {
        padding: 14px 8px 8px;
    }

    #airspot #column-left-relative #made-with-love img {
        width: 120px;
    }

    #column-right {
        width: 100vw;
        padding-left: calc(var(--col-size) + 24px);

        max-width: 1600px;
        margin: auto;
    }
    #column-right:after {
        right: -100px;
        transform: scale(0.85);
    }
}
