.loader {
    --c1: #cc2353;
    --c2: #f9cd16;
    --c3: #2e78f6;
    --c4: #e958b6;

    background: var(--gradient-sea-sky);
    opacity: 0;
    transition: opacity 0.1s;
}

.opacity {
    opacity: 1;
}

.loader::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    aspect-ratio: 8/5;
    background: conic-gradient(var(--c1) 50%, var(--c2) 0) no-repeat,
        conic-gradient(var(--c3) 50%, var(--c4) 0) no-repeat;
    background-size: 200% 50%;
    --_g: radial-gradient(#000 68%, #0000 71%) no-repeat;
    mask: var(--_g), var(--_g), var(--_g);
    mask-size: 25% 40%;
    animation: back 4s infinite steps(1), load 2s infinite;
}

@keyframes back {
    0%,
    100% {
        background-position: 0 0, 0 100%;
    }
    25% {
        background-position: 100% 0, 0 100%;
    }
    50% {
        background-position: 100% 0, 100% 100%;
    }
    75% {
        background-position: 0 0, 100% 100%;
    }
}

@keyframes load {
    0% {
        mask-position: 0 0, 50% 0, 100% 0;
    }
    16.67% {
        mask-position: 0 100%, 50% 0, 100% 0;
    }
    33.33% {
        mask-position: 0 100%, 50% 100%, 100% 0;
    }
    50% {
        mask-position: 0 100%, 50% 100%, 100% 100%;
    }
    66.67% {
        mask-position: 0 0, 50% 100%, 100% 100%;
    }
    83.33% {
        mask-position: 0 0, 50% 0, 100% 100%;
    }
    100% {
        mask-position: 0 0, 50% 0, 100% 0;
    }
}
