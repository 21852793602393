.card-img.sky {
    background: url(../../resources/nuage-1.svg) no-repeat bottom 50px right 0,
        url(../../resources/nuage-2.svg) no-repeat top 50px right 100px,
        url(../../resources/nuage-2.svg) no-repeat bottom 35px left 45%,
        url(../../resources/nuage-3.svg) no-repeat top 150px right 300px,
        url(../../resources/nuage-3.svg) no-repeat bottom 25px left -50px,
        url(../../resources/nuage-4.svg) no-repeat top 120px left 200px, var(--accent-grey-1);
}

.card-img .flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 24px;
}

.card-img .button-like {
    margin-left: 0;
}

.card-img .subtitle {
    font-size: 18px;
}

.card-img h1 {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
}

@media (max-width: 600px) {
    .card-img h1 {
        font-size: 32px;
    }

    .card-img .flex-col {
        padding: 1rem;
    }
}
