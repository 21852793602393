#menu-button {
    border: none;
    background-color: var(--accent-sea-blue);
    border-radius: 0.5rem;
    width: 3rem;
    height: 3rem;

    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
}

#menu-button:hover {
    background-color: var(--accent-sea-green);
}

.menu_wrapper {
    position: relative;
}

nav {
    position: absolute;
    z-index: 100;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
    right: 0;
    top: 3.5rem;
    width: max-content;
    overflow: hidden;
}

nav a {
    border: none;
    text-decoration: none;
    background-color: white;
    padding: 12px 32px;
    text-align: right;
    transition: all 0.25s;
    font-weight: bold;
}

nav a:hover,
nav a:focus {
    background-color: #d9d9d94c;
}

nav button {
    color: var(--text-color);
    border: none;
    text-decoration: none;
    background-color: white;
    padding: 12px 32px;
    text-align: right;
    transition: all 0.25s;
    font-weight: bold;
}

nav button:hover,
nav button:focus {
    background-color: #d9d9d94c;
}
