:root {
    --footer-size: min(90px, max(10vh, 55px));
}

.summit-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-bottom: var(--footer-size);
}

.summit-footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: var(--footer-size);
    display: flex;
    align-items: center;
    padding-left: 90px;
    padding-right: 24px;
    /* background: linear-gradient(93.58deg, #ffcb73 0%, #f97459 98.72%); */
    background: url(/public/stars.png), #1f0045;
}

.summit-footer {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 24px;
}

.summit__footer__jamespot {
    height: 100%;
}

.summit__footer__jamespot img {
    height: 100%;
    max-width: 100%;
}

.summit__footer__partenaires {
    display: none;
}

.summit__footer__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: white;
}

.summit__footer__message p {
    line-height: inherit;
}

@media screen and (min-width: 1024px) {
    .summit-footer {
        justify-content: space-between;
    }

    .summit__footer__logo {
        height: 100%;
    }

    .transparent {
        opacity: 0;
    }

    .fade-in {
        animation: fadeIn 3s;
    }

    .fade-out {
        animation: fadeOut 3s;
    }

    .summit__footer__partenaires {
        display: flex;
        gap: 10px;
        height: 100%;
        align-items: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: white;
    }

    .summit__footer__message {
        font-size: 20px;
        line-height: 10px;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    #scroll-text {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        -moz-animation: my-animation 25s linear infinite;
        -webkit-animation: my-animation 25s linear infinite;
        animation: my-animation 25s linear infinite;

        white-space: nowrap;
    }
}

#scroll-container {
    flex-grow: 1;
    border-radius: 5px;
    overflow: hidden;
}

#scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;

    white-space: nowrap;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

#axeptio_main_button {
    bottom: calc((var(--footer-size) - 48px) / 2) !important;
}
