.skeleton {
    position: relative;
    border-radius: 12px;
    height: 80px;
    background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
}

.skeleton:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 16px;
    border-radius: 12px 0 0 12px;
    background-color: #ccc;
}

@keyframes pulse {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -135% 0;
    }
}
