.card {
    width: 90%;
    max-width: var(--max-width);
    margin: auto;
    background-color: #f7f6fe;
    padding: 3rem;
    margin: 6rem auto;
    border-radius: 1.5rem;
    text-align: center;
}

.card h2 {
    font-size: 2rem;
}

.card p {
    display: block;
    margin: auto;
    max-width: 40rem;
    margin-bottom: 1.5rem;
}

.card .orange-dot-wrapper {
    position: absolute;
}

.card .orange-dot {
    top: -4rem;
    position: relative;
    height: 2rem;
    width: 2rem;
    background-color: var(--accent-orange);
    border-radius: 50%;
    z-index: -1;
}
