.button-like {
    --button-bg-color: var(--accent-dark-blue);
    --button-color: var(--accent-dark-blue-text-color);
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    transition: all 0.25s;
    box-shadow: var(--box-shadow);
    margin: 0.5rem;
    background-color: var(--button-bg-color);
    color: var(--button-color);
}

.button-dark-blue {
    --button-bg-color: var(--accent-dark-blue);
    --button-color: var(--accent-dark-blue-text-color);
}

.button-dark-blue:hover,
.button-dark-blue:focus {
    --button-bg-color: var(--accent-sea-blue);
    --button-color: var(--accent-sea-text-color);
    --button-outline-hover-color: var(--accent-dark-blue);
}

.button-sea-blue {
    --button-bg-color: var(--accent-sea-blue);
    --button-color: var(--accent-sea-text-color);
}

.button-purple {
    --button-bg-color: var(--accent-lavender);
    --button-color: var(--accent-lavender-text-color);
}

.button-purple:hover,
.button-purple:focus {
    --button-bg-color: var(--accent-lavender-light);
    --button-color: var(--accent-lavender);
    --button-outline-hover-color: var(--accent-lavender);
}

.button-sea-blue:hover,
.button-sea-blue:focus {
    --button-bg-color: var(--accent-dark-blue);
    --button-color: var(--accent-dark-blue-text-color);
    --button-outline-hover-color: var(--accent-sea-blue);
}

.button-orange {
    --button-bg-color: var(--accent-orange);
    --button-color: white;
}

.button-orange:hover,
.button-orange:focus {
    --button-bg-color: var(--accent-orange-very-light);
    --button-color: var(--accent-orange);
    --button-outline-hover-color: var(--accent-orange);
}

.button-round {
    border-radius: 2rem;
    padding: 0.5rem 3rem;
}

.button-outline {
    background-color: transparent;
    color: var(--button-bg-color);
    border: solid var(--button-bg-color) 0.1rem;
}

.button-outline:focus,
.button-outline:hover {
    color: white;
    background-color: var(--button-outline-hover-color);
    border: solid var(--button-outline-hover-color) 0.1rem;
}
