html {
    --scrollbarTrack: white;
    --scrollbarThumb: #bebed1;
}
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarThumb) var(--scrollbarTrack);
    scroll-behavior: smooth;
}
*::-webkit-scrollbar-track {
    background: var(--scrollbarTrack);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumb);
}
*::-webkit-scrollbar {
    width: 8px;
}
