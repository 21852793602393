.map-presentation__title {
    width: min(80vw, 1200px);
    text-align: center;
    margin: auto;
}

.map-presentation__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
    width: min(80vw, 1200px);
    margin: auto;
}

.map-presentation__grid div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.map-presentation__grid img {
    width: 100%;
}

.map-presentation__grid p {
    text-align: center;
    font-weight: bold;
}

@media (min-width: 660px) {
    .map-presentation__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1200px) {
    .map-presentation__grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
