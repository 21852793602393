.img-wrapper {
    max-height: calc(100vh - 9rem);
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    background: var(--gradient-sea-sky);
}

.img-wrapper img {
    width: 100%;
    min-width: 720px;
    max-height: calc(100vh - 12rem);
    object-fit: scale-down;
    aspect-ratio: 4096/2458;
}

.img-wrapper .svg-wrapper {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-width: 720px;
}

.svg-wrapper svg {
    max-height: calc(100vh - 12rem);
    width: 100%;
}

.svg-zone-fill {
    opacity: 0;
    transition: all 0.25s;
}

.svg-zone:hover .svg-zone-fill,
.svg-zone:focus .svg-zone-fill {
    opacity: 100;
}

.svg-zone:focus-visible {
    outline: none;
}

.svg-text {
    font-size: 65px;
    font-weight: bold;
    opacity: 0;
    transition: all 0.2s;
    filter: drop-shadow(0 0 10px white);
}

.svg-zone:hover .svg-text,
.svg-zone:focus .svg-text {
    opacity: 1;
}

.why-jamespot {
    background-color: rgba(82, 221, 231, 0.1);
    padding-top: 1rem;
    padding-bottom: 2rem;
}

@media (max-width: 660px) {
    .header-button {
        margin-top: 16px;
    }

    header h1 {
        line-height: 28px;
        padding-bottom: 8px;
    }
}
